.show-xs,.show-sm,.show-md,.show-lg,.show-xl{display: none}
/*// Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
    .hidden-xs{display: none}
    .show-xs{display: initial}
}

 /*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
    .hidden-sm{display: none}
    .show-sm{display: initial}
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
    .hidden-md{display: none}
    .show-md{display: initial}
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
    .hidden-lg{display: none}
    .show-lg{display: initial}
}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .hidden-xl{display: none}
    .show-xl{display: initial}
}

/*自适应*/
img.fit{
    width:100%;
}
p.fit{
    width:100%;
}
.transition-all{
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}
.mobile-tips{
    background: #f42c2c;
    color: #ffffff;
    text-align: center;
}
/*头部*/
.header>nav{
    background:white;
    padding-top:0;
    padding-bottom:0;
}
.header>nav .nav-link{
    padding:1.4rem 0.4rem!important;
    margin:auto 1.6rem;
    border-bottom:4px solid transparent;
}
.header>nav .nav-link:hover{
    border-bottom-color:#d42609;
}
/*底部*/
.footer{
    /*margin-top:1rem;*/
    background:#b60c24;
    padding-top:1rem;
    color:white;
    font-size:0.8rem;
}
.footer a{
    color:white;
}
.footer a:hover{
    color:#ddd;
}
.footer .column>span{
    display:inline-block;
    margin-right:1rem;
    margin-left:1rem;
}
.contact .block{
    padding:2rem 3rem;
}
.contact .name{
    /*font-weight:bold;*/
    font-size:2rem;
    color:#777;
}
.contact .info{
    font-size:1rem;
    line-height:1rem;
    color:#555;
    color:#222;
}
.contact .wx_qr{
    width: 10rem;
    margin-left: 3rem;
    margin-top: -2.5rem;
}
.contact .bus_wx_qr{
    width: 20rem;
    max-width: 100%;
    margin-top: -2.5rem;
}
/*首页大图*/
.home-big-pic{
    max-height: 350px;
    overflow:hidden;
}
.title-s1{
    text-align:center;
    width: 100%;
    margin-top:2.5rem;
    margin-bottom:1rem;
}
.title-s1 p{
    display:inline-block;
    text-align:center;
    font-size:1.8rem;
    letter-spacing:0.2rem;
    font-weight:bold;
    padding:0 1rem 0.5rem;
    border-bottom:5px solid #d42609;
}
.title-s1 p>span{
    display:inline-block;
    text-align:center;
    color:#919191;
    font-size:1.5rem;
    padding-left:1rem;
}
.desc-s1{
    padding:0 1rem;
    color:#666;
    letter-spacing:1px;
}
.bottom-s1{
    margin-bottom:1rem;
}
.left-s1{
    margin-left:1rem!important;
}
.block-s2{
    margin:0.3rem;
    position: relative;
    overflow: hidden;
    cursor:pointer;
}
.block-s2>.desc{
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    
    position: absolute;
    bottom:0;

    padding:1rem 1rem 0 1rem;
    word-break: break-all;
    overflow: hidden;
    height: 4.5rem;
    margin-bottom: 0;
    color:#ddd;
    font-size:1.5rem;
    line-height:2rem;
    z-index: 1;
    width: 100%;
    background: #97979742;
}
.block-s2>.desc p:first-child{
    font-size:1.5rem;
    margin-bottom: 0;
    white-space:nowrap;
}
.block-s2>.desc p:nth-child(2){
    margin: -0.3rem 0 0 0 ;
    font-size: 0.8rem;
}
.block-s2>.desc .more{
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    
    margin: 0 0 0 0 ;
    font-size: 1.2rem;
    opacity:0;
}
.block-s2 a{
    color:white;
}
.block-s2 img{
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.block-s2:hover img{
    transform: scale(1.5);
}
.block-s2:hover>.desc{
    height:7rem;
    background:#00000050;
}
.block-s2:hover>.desc .more{
    opacity:1;
}
